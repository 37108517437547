import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStore } from '@twist/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import {
  faBars,
  faBell,
  faChevronDown,
  faHamburger,
  faMoon,
  faSearch,
  faSun,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LayoutStore } from '@twist/ui-core';
import { AvatarComponent, TwistLogoComponent } from '@twist/ui/components';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-toolbar-nav',
  imports: [CommonModule, NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, FaIconComponent, TwistLogoComponent, AvatarComponent, RouterLink],
  templateUrl: './toolbar-nav.component.html'
})
export class ToolbarNavComponent {

  #authStore = inject(AuthStore);
  #layoutStore = inject(LayoutStore);

  currentUser = this.#authStore.user;
  theme = this.#layoutStore.theme;

  toggleSidebar() {
    this.#layoutStore.toggleSidebar();
  }

  logout() {
    this.#authStore.logout();
  }

  protected readonly faMoon = faMoon;

  toggleTheme() {
    this.#layoutStore.toggleTheme();
  }

  protected readonly faHamburger = faHamburger;
  protected readonly faBars = faBars;
  protected readonly faUser = faUser;
  protected readonly faSearch = faSearch;
  protected readonly faSun = faSun;
  protected readonly faBell = faBell;
  protected readonly faChevronDown = faChevronDown;
}
