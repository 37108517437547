import { Component, computed, ElementRef, inject, signal, Signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faClose, faPaperPlane, faRobot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AiAssistantStore } from '../../stores/ai-assistant.store';
import { ChatHistoryEntry } from '../../../../../../../common/backbone-api-services/src/lib/generated/ai-assistant';
import { FormsModule } from '@angular/forms';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';

@Component({
  selector: 'lib-ai-chat',
  standalone: true,
  imports: [CommonModule, FaIconComponent, FormsModule, MarkdownComponent],
  templateUrl: './ai-chat.component.html',
  providers: [
    provideMarkdown()
  ],
  styleUrl: './ai-chat.component.scss'
})
export class AiChatComponent {


  aiAssistantStore = inject(AiAssistantStore);
  userMessage = signal('');

  chatWindowVisible: Signal<boolean> = this.aiAssistantStore.chatbotWindowVisible;
  chatHistory: Signal<ChatHistoryEntry[]> = computed(() => {

    console.log("UPDATING computed", this.aiAssistantStore.chatHistoryEntities())
   this.aiAssistantStore.waiting();
    return this.aiAssistantStore.chatHistoryEntities();

  })

  toggleChatbotWindow = () => {
    this.aiAssistantStore.toggleChatbotWindow();
  }

  protected readonly faRobot = faRobot;
  protected readonly faClose = faClose;
  protected readonly faPaperPlane = faPaperPlane;

  sendUserMessage($event: any) {
    this.aiAssistantStore.sendMessage(this.userMessage());
    this.userMessage.set('');

    $event.stopPropagation();
    $event.preventDefault();
  }

  protected readonly faSpinner = faSpinner;
}
