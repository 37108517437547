<footer class="footer">
  <div class="container-fluid">
    <div class="row text-muted">
      <div class="col-6 text-start">
        <p class="mb-0">
          <a class="text-muted" href="https://twist-iot.com/" target="_blank"><strong>
            <ui-twist-logo variant="compact" style="width: 0.5em; display: inline-block"></ui-twist-logo>
            Twist Innovations</strong></a> &copy;
        </p>
      </div>
      <div class="col-6 text-end">
        <ul class="list-inline">
          <li class="list-inline-item">
            <a class="text-muted" href="https://twist-iot.com/support" target="_blank">Support</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" href="https://twist-iot.com/documentation" target="_blank">Help Center</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" href="https://twist-iot.com/privacy" target="_blank">Privacy</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" href="https://twist-iot.com/privacy" target="_blank">Terms</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
