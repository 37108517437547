import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SidebarNavComponent } from '../../components/sidebar-nav/sidebar-nav.component';
import { ToolbarNavComponent } from '../../components/toolbar-nav/toolbar-nav.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { AiChatComponent } from '@twist/feature-ai-chat';

@Component({
  standalone: true,
  imports: [CommonModule, SidebarNavComponent, ToolbarNavComponent, RouterOutlet, FooterComponent, AiChatComponent],
  templateUrl: './portal-layout.component.html'
})
export class PortalLayoutComponent {
}

