<div class="ai-chat"
     [class.show-chatbot]="chatWindowVisible()">

  <button class="chatbot-toggler" (click)="toggleChatbotWindow()">
    <span><fa-icon [icon]="faRobot"></fa-icon></span>
    <span><fa-icon [icon]="faClose"></fa-icon></span>
  </button>
  <div class="chatbot">
    <header>
      <h2>Twistbot</h2>
      <span class="close-btn material-symbols-outlined">close</span>
    </header>
    <ul class="chatbox" id="chatMessages" #scrollMe [scrollTop]="scrollMe.scrollHeight">
      <li class="chat incoming">
        <span><fa-icon [icon]="faRobot" [fixedWidth]="true"></fa-icon></span>
        <p>How can I help you today?</p>
      </li>
      @for (entry of chatHistory(); track $index) {
        <li class="chat outgoing">
          <p>{{ entry.userMessage }}</p>
        </li>
        <li class="chat incoming">
          <span><fa-icon [icon]="faRobot" [fixedWidth]="true"></fa-icon></span>
          <p>
            @if(entry.assistantAnswer){
            <markdown [data]="entry.assistantAnswer" class="markdown" />
            } @else {
              <fa-icon [icon]="faSpinner" animation="spin-pulse" > </fa-icon>
            }
          </p>
        </li>
      }
    </ul>
    <div class="chat-input">
      <textarea [(ngModel)]="userMessage" placeholder="Enter a message..." spellcheck="false" (keydown.enter)="sendUserMessage($event)" rows="1" required></textarea>
      <span (click)="sendUserMessage($event)" id="send-btn"><fa-icon [icon]="faPaperPlane"></fa-icon></span>
    </div>
  </div>

</div>


