import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwistLogoComponent } from '@twist/ui/components';

@Component({
  standalone: true,
  selector: 'app-footer',
  imports: [CommonModule, TwistLogoComponent],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
}
