import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AuthStore } from '@twist/authentication/core';
import { ApplicationStore } from '@twist/core';


@Component({
  standalone: true,
  imports: [
    RouterModule,
    NgbModule,
    HttpClientModule
],
  selector: 'twist-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private _authStore = inject(AuthStore);
  private _applicationStore = inject(ApplicationStore);

  title = 'twist-portal';

  async ngOnInit(): Promise<void> {
    await this._authStore.initialize();
    this._applicationStore.initialize();
  }

}
