import { Component, computed, inject, OnInit, signal } from '@angular/core';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel, RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterModule
} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AuthStore } from '@twist/core';
import { ApplicationStore } from '@twist/core';
import { NgIf } from '@angular/common';
import { LoadingComponent } from '@twist/ui/components';

import { LayoutStore } from '@twist/ui-core';


@Component({
  standalone: true,
  imports: [
    RouterModule,
    NgbModule,
    HttpClientModule,
    NgIf,
    LoadingComponent
  ],
  selector: 'twist-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private _layoutStore = inject(LayoutStore);
  private _authStore = inject(AuthStore);
  private _router = inject(Router);
  private _applicationStore = inject(ApplicationStore);


  private navigationLoading = signal(true)

  public loading = computed(() => {
    return this._authStore.isLoading() || this.navigationLoading();
  })

  title = 'twist-portal';

  async ngOnInit(): Promise<void> {
    this.initializeLoader();
    this._applicationStore.initialize();
  }

  private initializeLoader() {
    this._router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.navigationLoading.set(true);
      }
      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.navigationLoading.set(false);
      }
      if (event instanceof RouteConfigLoadStart) {
        this.navigationLoading.set(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.navigationLoading.set(false);
      }
    });
  }
}
