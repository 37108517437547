import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';

@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string
  ) {
    super(platformLocation, _baseHref);
  }

  private preserveParams = [
    "debugmode"
  ]

  override prepareExternalUrl(internal: string): string {

    // join path with slashes and baseHref (internal, see angular source :D )
    const path = super.prepareExternalUrl(internal);

    // get the current query parameter and extract the ones we want to preserve
    const urlSearchParamsToKeep = new URLSearchParams()
    new URLSearchParams(this.search).forEach((value, key) => {
      if(this.preserveParams.includes(key.toLowerCase())) {
        urlSearchParamsToKeep.append(key, value);
      }
    });

    // create object literal from an iterator of URLSearchParams (key, value) pair
    const existingQueryParams = Object.fromEntries(urlSearchParamsToKeep.entries());

    // parse (deserialize) the final url path and get the corresponding url tree for easier data manipulation
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;

    // merge existing query params that we want to keep with the next route's queryParams
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };

    // serialise urlTree
    return urlTree.toString();
  }
}
