import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faClose,
  faDashboard,
  faGear,
  faList,
  faPlus,
  faRightFromBracket,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { LayoutStore } from '@twist/ui-core';
import { AuthStore } from '@twist/authentication/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconComponent, SvgIcons, TwistLogoComponent } from '@twist/ui/components';

@Component({
  standalone: true,
  selector: 'app-sidebar-nav',
  imports: [CommonModule, FaIconComponent, RouterLinkActive, RouterLink, NgScrollbar, NgbCollapse, TwistLogoComponent, SvgIconComponent],
  templateUrl: './sidebar-nav.component.html',
})
export class SidebarNavComponent {

  #layoutStore = inject(LayoutStore);
  #authStore = inject(AuthStore)

  //sidebarCollapsed = computed(() => this.#layoutStore.sidebarCollapsed())

  logout($event: MouseEvent) {
    this.#authStore.logout();
  }

  protected readonly faDashboard = faDashboard;
  protected readonly faList = faList;
  protected readonly faGear = faGear;
  protected readonly faUser = faUser;
  protected readonly faRightFromBracket = faRightFromBracket;
  protected readonly faPlus = faPlus;
  isDashboardCollapsed = false;
  isBuildupCollapsed = true;
  protected readonly faClose = faClose;

  toggleSidebar() {
    this.#layoutStore.toggleSidebar()
  }

  protected readonly SvgIcons = SvgIcons;
}
