import { EnvironmentSettings } from '@twist/core';

export const environment : EnvironmentSettings = {
  mqttBrokerUrl: 'wss://mqtt-dev.twist-innovation.com',
  production: false,
  pageTitlePrefix: "[ DEV ] - ",
  backboneUrl: "https://backbone-dev.twist-innovation.com",
  ciamUrl: "https://auth-dev.twist-innovation.com",
  backboneApiKey: "968dbe17-1c9e-4b95-9ad9-1346d4a4cc70",
  signalrUlr: "https://signalr-dev.twist-innovation.com"
};
