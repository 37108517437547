import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppSettings } from '@twist/core';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategy extends TitleStrategy {

  private _appSettings = inject(AppSettings);

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const prefix = this._appSettings.environment.pageTitlePrefix;
    if (title !== undefined) {
      this.title.setTitle(`${prefix}Twist | ${title}`);
    } else {
      this.title.setTitle(`${prefix}Twist`);
    }
  }
}
