<div class="wrapper">
  <app-toolbar-nav></app-toolbar-nav>
  <app-sidebar-nav></app-sidebar-nav>
  <div class="content-page">
    <main class="content">
      <!--
            <div class="router-wrapper" [@routeAnimations]="getRouteAnimationData()">
      -->
      <div class="router-wrapper">
        <router-outlet></router-outlet>
      </div>
    </main>
    <app-footer></app-footer>
  </div>
</div>
<lib-ai-chat></lib-ai-chat>
