<!-- ========== Left Sidebar Start ========== -->
<div class="leftside-menu sidebar-enable">
  <a routerLink="/" class="logo">
    <span class="logo-lg">
      <ui-twist-logo variant="full"></ui-twist-logo>
    </span>
    <span class="logo-sm">
      <ui-twist-logo variant="compact"></ui-twist-logo>
    </span>
  </a>

  <!-- Sidebar Hover Menu Toggle Button -->
  <div class="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
    <i class="ri-checkbox-blank-circle-line align-middle"></i>
  </div>

  <!-- Full Sidebar Menu Close Button -->
  <div class="button-close-fullsidebar" (click)="toggleSidebar()">
    <fa-icon [icon]="faClose" class="align-middle"></fa-icon>
  </div>

  <!-- Sidebar -left -->
  <nav class="h-100" id="leftside-menu-container">

    <ng-scrollbar>
      <!--- Sidemenu -->
      <ul class="side-nav">

        <li class="side-nav-title">My twist</li>

        <li class="side-nav-item" routerLinkActive="menuitem-active" [routerLinkActiveOptions]="{exact:true}">
          <a data-bs-toggle="collapse"
             routerLink="/dashboard"
             aria-expanded="false"
             aria-controls="sidebarDashboards"
             (click)="isDashboardCollapsed = !isDashboardCollapsed"
             class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.dashboard" size="large" />
            <span class="badge bg-success rounded-pill float-end">5</span>
            <span> Dashboard </span>
          </a>
          <!--<div class="collapse" id="sidebarDashboards" #collapse="ngbCollapse" [ngbCollapse]="isDashboardCollapsed">
            <ul class="side-nav-second-level">
              <li>
                <a routerLink="/">Analytics</a>
              </li>
              <li>
                <a routerLink="/">Ecommerce</a>
              </li>
              <li>
                <a routerLink="/">Projects</a>
              </li>
            </ul>
          </div>-->
        </li>

        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="/installations"
             class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.installations" size="large" />
            <span> Installations </span>
          </a>
        </li>

        <li class="side-nav-title">Administration</li>

        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a data-bs-toggle="collapse"
             routerLink="/manufacturing"
             aria-expanded="false"
             aria-controls="sidebarEcommerce"
             class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.gearsDatabase" size="large" />
            <span> Manufacturing </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarEcommerce">
            <ul class="side-nav-second-level">
              <li>
                <a href="apps-ecommerce-products.html">Products</a>
              </li>
              <li>
                <a href="apps-ecommerce-products-details.html">Products Details</a>
              </li>
            </ul>
          </div>
        </li>

        <li class="side-nav-title">Buildups</li>

        <li class="side-nav-item" routerLinkActive="menuitem-active" [routerLinkActiveOptions]="{exact:true}">
          <a data-bs-toggle="collapse"
             routerLink="/hardware-buildup/devices"
             aria-expanded="false"
             aria-controls="sidebarBuildups"
             (click)="isBuildupCollapsed = !isBuildupCollapsed"
             class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.gears" size="large" />
            <!--            <span class="badge bg-success float-end">5</span>-->
            <span> Devices </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarBuildups" #collapse="ngbCollapse" [ngbCollapse]="isBuildupCollapsed">
            <ul class="side-nav-second-level">
              <li routerLinkActive="menuitem-active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/hardware-buildup/devices">Twist Devices</a>
              </li>
              <li routerLinkActive="menuitem-active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/hardware-buildup/devices">Manufacturing Devices</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="/hardware-buildup/firmware" class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.wind" size="large" />
            <!--            <span class="badge bg-success float-end">5</span>-->
            <span> Firmware </span>
          </a>

        </li>
        <li class="side-nav-item" routerLinkActive="menuitem-active">
          <a routerLink="/hardware-buildup/routine-blueprints" class="side-nav-link">
            <ui-svg-icon [icon]="SvgIcons.wind" size="large" />
            <!--            <span class="badge bg-success float-end">5</span>-->
            <span> Routine blueprints </span>
          </a>

        </li>
        <!--


                <li class="side-nav-title">Account and settings</li>

                <li class="side-nav-item">
                  <a data-bs-toggle="collapse" href="#sidebarPages" aria-expanded="false" aria-controls="sidebarPages"
                     class="side-nav-link">
                    <fa-icon [icon]="faDashboard"></fa-icon>
                    <span> Pages </span>
                    <span class="menu-arrow"></span>
                  </a>
                  <div class="collapse" id="sidebarPages">
                    <ul class="side-nav-second-level">
                      <li>
                        <a href="pages-profile.html">Profile</a>
                      </li>
                      <li>
                        <a href="pages-profile-2.html">Profile 2</a>
                      </li>
                      <li>
                        <a href="pages-invoice.html">Invoice</a>
                      </li>
                      <li>
                        <a href="pages-faq.html">FAQ</a>
                      </li>
                      <li>
                        <a href="pages-pricing.html">Pricing</a>
                      </li>
                      <li>
                        <a href="pages-maintenance.html">Maintenance</a>
                      </li>
                      <li class="side-nav-item">
                        <a data-bs-toggle="collapse" href="#sidebarPagesAuth" aria-expanded="false"
                           aria-controls="sidebarPagesAuth">
                          <span> Authentication </span>
                          <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="sidebarPagesAuth">
                          <ul class="side-nav-third-level">
                            <li>
                              <a href="pages-login.html">Login</a>
                            </li>
                            <li>
                              <a href="pages-login-2.html">Login 2</a>
                            </li>
                            <li>
                              <a href="pages-register.html">Register</a>
                            </li>
                            <li>
                              <a href="pages-register-2.html">Register 2</a>
                            </li>
                            <li>
                              <a href="pages-logout.html">Logout</a>
                            </li>
                            <li>
                              <a href="pages-logout-2.html">Logout 2</a>
                            </li>
                            <li>
                              <a href="pages-recoverpw.html">Recover Password</a>
                            </li>
                            <li>
                              <a href="pages-recoverpw-2.html">Recover Password 2</a>
                            </li>
                            <li>
                              <a href="pages-lock-screen.html">Lock Screen</a>
                            </li>
                            <li>
                              <a href="pages-lock-screen-2.html">Lock Screen 2</a>
                            </li>
                            <li>
                              <a href="pages-confirm-mail.html">Confirm Mail</a>
                            </li>
                            <li>
                              <a href="pages-confirm-mail-2.html">Confirm Mail 2</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="side-nav-item">
                        <a data-bs-toggle="collapse" href="#sidebarPagesError" aria-expanded="false"
                           aria-controls="sidebarPagesError">
                          <span> Error </span>
                          <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="sidebarPagesError">
                          <ul class="side-nav-third-level">
                            <li>
                              <a href="pages-404.html">Error 404</a>
                            </li>
                            <li>
                              <a href="pages-404-alt.html">Error 404-alt</a>
                            </li>
                            <li>
                              <a href="pages-500.html">Error 500</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="pages-starter.html">Starter Page</a>
                      </li>
                      <li>
                        <a href="pages-preloader.html">With Preloader</a>
                      </li>
                      <li>
                        <a href="pages-timeline.html">Timeline</a>
                      </li>
                    </ul>
                  </div>
                </li>
        -->


        <!-- Help Box -->
        <div class="help-box text-white text-center">
          <a href="javascript: void(0);" class="float-end close-btn text-white">
            <i class="mdi mdi-close"></i>
          </a>
          <ui-twist-logo variant="compact" height="40"></ui-twist-logo>
          <h4 class="mt-3">Need help?</h4>
          <p class="mb-3">Consult our extensive documentation and training material.</p>
          <a href="javascript: void(0);" class="btn btn-primary btn-sm">Twist help center</a>
        </div>
        <!-- end Help Box -->


      </ul>
      <!--- End Sidemenu -->

    </ng-scrollbar>
    <div class="clearfix"></div>
  </nav>
</div>
<!-- ========== Left Sidebar End ========== -->

<!--


<nav id="sidebar" class="sidebar" [class.collapsed]="sidebarCollapsed()">
  <ng-scrollbar style="height: 100vh">
    <div class="sidebar-content">
      <a class="sidebar-brand" routerLink="/">
      <span class="align-middle">
        <ui-twist-logo height="40px" width="100%"></ui-twist-logo>
      </span>
      </a>

      <div class="sidebar-nav-wrapper">

        <ul class="sidebar-nav">

          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact : true}"
               ariaCurrentWhenActive="page">
              <fa-icon [icon]="faDashboard"></fa-icon>
              <span class="align-middle">Dashboard</span>
            </a>
          </li>

          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/installations" routerLinkActive="active" ariaCurrentWhenActive="page">
              <fa-icon [icon]="faList"></fa-icon>
              <span class="align-middle">Installations</span>
            </a>
          </li>


          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/manufacturing" routerLinkActive="active" ariaCurrentWhenActive="page">
              <fa-icon [icon]="faList"></fa-icon>
              <span class="align-middle">Manufacturing</span>
            </a>
          </li>

          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact : true}"
               ariaCurrentWhenActive="page">
              <fa-icon [icon]="faPlus"></fa-icon>
              <span class="align-middle">Add installation</span>
            </a>
          </li>

          &lt;!&ndash;
                    <li class="sidebar-item">
                      <a class="sidebar-link" routerLink="/hardware" routerLinkActive="active" ariaCurrentWhenActive="page">
                        <fa-icon [icon]="faList"></fa-icon>
                        <span class="align-middle">Products</span>
                      </a>
                    </li>
                    <li class="sidebar-item">
                      <a class="sidebar-link" routerLink="/hardware" routerLinkActive="active" ariaCurrentWhenActive="page">
                        <fa-icon [icon]="faList"></fa-icon>
                        <span class="align-middle">Devices</span>
                      </a>
                    </li>

                    <li class="sidebar-item">
                      <a class="sidebar-link" routerLink="/hardware" routerLinkActive="active" ariaCurrentWhenActive="page">
                        <fa-icon [icon]="faList"></fa-icon>
                        <span class="align-middle">Translations</span>
                      </a>
                    </li>&ndash;&gt;
        </ul>

        <ul class="sidebar-nav">
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/auth/account/profile" routerLinkActive="active" ariaCurrentWhenActive="page">
              <fa-icon [icon]="faUser"></fa-icon>
              <span class="align-middle">My account</span>
            </a>
          </li>

          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/auth/account/preferences" routerLinkActive="active" ariaCurrentWhenActive="page">
              <fa-icon [icon]="faGear"></fa-icon>
              <span class="align-middle">Preferences</span>
            </a>
          </li>

          <li class="sidebar-item">
            <a class="sidebar-link" href="#" (click)="logout($event)">
              <fa-icon [icon]="faRightFromBracket"></fa-icon>
              <span class="align-middle">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ng-scrollbar>
</nav>
-->
