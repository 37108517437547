import {
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core';
import { provideRouter, TitleStrategy } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LayoutStore } from '@twist/ui-core';
import { PageTitleStrategy } from './services/page-title-strategy.service';
import {
  UserManagementApiConfiguration,
  UserManagementApiModule
} from '@twist/backbone-api-services/user-management';
import {
  InstallationsApiConfiguration,
  InstallationsApiModule
} from '@twist/backbone-api-services/installations';
import {
  ManufacturingApiConfiguration,
  ManufacturingApiModule
} from '@twist/backbone-api-services/manufacturing';
import { environment } from '../environments';

import {
  InfrastructureApiConfiguration,
  InfrastructureApiModule
} from '@twist/backbone-api-services/infrastructure';
import { CoreModule } from '@twist/core';
import { provideLogging } from '@twist/logging';
import { provideToastr, ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { PathPreserveQueryLocationStrategy } from './services/path-preserve-query-location-strategy';
import { LocationStrategy } from '@angular/common';
import {
  HardwareBuildupApiConfiguration,
  HardwareBuildupApiModule
} from '@twist/backbone-api-services/hardware-buildup';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import {
  AiAssistantApiConfiguration,
  AiAssistantApiModule
} from '@twist/backbone-api-services/ai-assistant';

// angular material - Disable teh ripple effect
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    provideOAuthClient(),
    provideLogging(),
    provideAnimations(), // required animations providers
    provideCharts(withDefaultRegisterables()),
    provideToastr({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    LayoutStore,
    importProvidersFrom(
      CoreModule.forRoot(environment),
      UserManagementApiModule.forRoot(() => {
        return new UserManagementApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      InstallationsApiModule.forRoot(() => {
        return new InstallationsApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      ManufacturingApiModule.forRoot(() => {
        return new ManufacturingApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      HardwareBuildupApiModule.forRoot(() => {
        return new HardwareBuildupApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      InfrastructureApiModule.forRoot(() => {
        return new InfrastructureApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      AiAssistantApiModule.forRoot(() => {
        return new AiAssistantApiConfiguration({
          basePath: environment.backboneUrl
        });
      }),
      MonacoEditorModule.forRoot(),
      ToastrModule.forRoot()
    ), provideAnimationsAsync()
  ]
};
