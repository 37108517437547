import { inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AppSettings } from '@twist/core';
import { Logger } from '@twist/logging';

/**
 * Interceptor that adds an API key to the request headers for API calls.
 */
@Injectable()
export class AddApiKeyInterceptor implements HttpInterceptor {

  private _appSettings = inject(AppSettings);
  private _logger = inject(Logger)

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isApiUrl(request)) {
      this._logger.debug('[ApiKeyInterceptor] - 🔑', request.url);
      const apiRequest = request.clone({
        setHeaders: {
          'X-API-Key': this._appSettings.environment.backboneApiKey
        }
      });
      return next.handle(apiRequest);
    }
    this._logger.debug('[ApiKeyInterceptor] - ignored', request.url);
    return next.handle(request);
  }

  private isApiUrl(request: HttpRequest<any>): boolean {
    return request.url.startsWith(this._appSettings.environment.backboneUrl);
  }
}
